import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ArrayInput,
  BooleanInput,
} from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin, isTemplateAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequired, validateRequiredText } from 'utils/validation';
import { PUBLISHING_STATUS_OPTIONS } from './index';
import SortableModulesIterator from './components/SortableModulesIterator';
import { FormatButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import useRichTextStyles from 'hooks/useRichTextStyles';
import RichTextToolbar from 'components/RichTextToolbar';

const InfLearningTemplateCreate = props => {
  const classes = useRichTextStyles();

  return (
    <Create redirect='list' title='Create a INF Learning Template' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput source='name' validate={validateRequiredText} />

        <SelectInput
          source='status'
          label='Publishing Status'
          validate={validateRequired}
          choices={PUBLISHING_STATUS_OPTIONS}
        />
        <br />

        <RichTextInput
          name='description'
          source='description'
          label='Campaign Description'
          toolbar={RichTextToolbar}
          formClassName={classes.richTextContainer}
        />
        <RichTextInput
          source='instructions'
          label='Campaign Instructions'
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
            </RichTextInputToolbar>
          }
          formClassName={classes.richTextContainer}
        />
        <br />
        <ReferenceArrayInput
          source='markets'
          label='Work Sectors'
          reference='AdminMarket'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source='templateCategories'
          label='Category'
          reference='AdminTemplateCategory'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <BooleanInput label='Include Discussion Forum' name='includeDiscussionForum' source='includedDiscussionForum' />

        {/* Activity Sequencing */}
        <BooleanInput label='Enforce Activity Sequence' name='enforceActivitySequence' source='enforceActivitySequence' />
        <BooleanInput label='Allow Sequence Override' name='allowSequenceOverride' source='allowSequenceOverride' />
        <br />

        <ArrayInput source='modules'>
          <SortableModulesIterator />
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default lockDown(InfLearningTemplateCreate, [isUCPAdmin, isTemplateAdmin]);
