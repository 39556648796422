export const MODULE_ACCESS = {
  REGISTERED_NON_MEMBER: 'registered non-member',
  GUEST: 'guest',
  MEMBER: 'member',
  PRIVATE: 'private',
};

export const JOB_FUNCTION_NAMES = {
  QUIZ_ATTEMPT_REPORT: 'quizAttemptReport',
  USER_EXPORT_REPORT: 'userExportReport',
  USER_BADGE_REPORT: 'userBadgeReport',
  BATCH_USER_UPDATE: 'batchEmailUpdate',
};

export const MODULE_STATUS_PUBLISHED = 'published';

export const MAX_FILE_SIZE = 5000000;
