import React from 'react';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SecuredModuleCreate from './ModuleCreate';
import SecuredModuleList from './ModuleList';
import SecuredModuleEdit from './ModuleEdit';
import {
    AutocompleteInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    useRecordContext
} from 'react-admin';
import { MODULE_ACCESS } from '../../../utils/consts';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

// Converted to Mime types '.doc,.docx,.pdf,.ppt,.csv,.xlsx'
export const ALLOWED_MODULE_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ACCESS_LEVEL_OPTIONS = [
  { id: MODULE_ACCESS.REGISTERED_NON_MEMBER, name: 'Registered Non-Member' },
  { id: MODULE_ACCESS.MEMBER, name: 'Member' },
  { id: MODULE_ACCESS.PRIVATE, name: 'Private' },
];

export const PUBLISHING_STATUS_OPTIONS = [
  { id: 'draft', name: 'Draft' },
  { id: 'published', name: 'Published' },
  { id: 'archived', name: 'Archived' },
];

export const ModuleIcon = ViewModuleIcon;

export const ModuleTitle = () => {
  const record = useRecordContext();
  return <span>Module {record ? `"${record.title}"` : ''}</span>;
};

export const ModuleFilter = props => (
  <SavelessFilter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <SelectInput source='accessLevel' label='Access Level' choices={ACCESS_LEVEL_OPTIONS} />
    <SelectInput source='publishingStatus' label='Publishing Status' choices={PUBLISHING_STATUS_OPTIONS} />
    <ReferenceInput
      label='Presenter'
      source='presenters'
      reference='AdminPresenter'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='Occupation'
      source='occupations'
      reference='AdminOccupation'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='Categories'
      source='categories'
      reference='AdminCategory'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <BooleanInput source='isBadgeContent' label='Badges' sx={{marginBottom: '0.625rem'}} />
  </SavelessFilter>
);

export { SecuredModuleList, SecuredModuleCreate, SecuredModuleEdit };
