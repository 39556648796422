import React, { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  SelectInput,
  BooleanInput,
  TopToolbar,
  DateInput,
} from 'react-admin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import CentralDateField from 'components/CentralDateField';
import { JOB_FUNCTION_NAMES } from 'utils/consts';
import CustomExportButton from '../../CustomExportButton';
import moment from 'moment';
import LearningCreditField from './LearningCreditField';
import get from 'lodash/get';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

const statusOptions = [
  { id: 'Pass', name: 'Pass' },
  { id: 'Fail', name: 'Fail' },
  { id: 'Incomplete', name: 'Incomplete' },
];

const useStyles = makeStyles({
  quizAttemptList: {
    '& > .MuiToolbar-root': {
      zIndex: 10,
    },
  },
});

export const QuizAttemptIcon = LocationCityIcon;

function endOfDay(value) {
  return moment(value)
    .endOf('day')
    .toISOString();
}

const QuizAttemptFilter = props => (
  <SavelessFilter {...props}>
    <ReferenceInput label='User' source='user' reference='AdminUser' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='email' />
    </ReferenceInput>
    <ReferenceInput label='State' source='userMeta.state' reference='AdminState' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput label='Market' source='markets' reference='AdminMarket' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput label='Member Agency' reference='AdminAgency' source='memberAgency'>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <ReferenceInput label='Module' source='moduleId' reference='AdminModule' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='title' />
    </ReferenceInput>
    <ReferenceInput label='Work Sector' source='moduleMeta.markets' reference='AdminMarket'>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <SelectInput label='Status' source='status' choices={statusOptions} />
    <BooleanInput label='Earned Certificate' source='certificateOfCompletion' />
    <DateInput source='createdAt.startDate' label='Start Date' />
    <DateInput source='createdAt.endDate' label='End Date' parse={endOfDay} />
  </SavelessFilter>
);

const QuizAttemptsListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => {
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CustomExportButton jobName={JOB_FUNCTION_NAMES.QUIZ_ATTEMPT_REPORT} />
    </TopToolbar>
  );
};

const QuizAttemptList = props => {
  const classes = useStyles();

  return (
    <List
      className={classes.quizAttemptList}
      title='Quiz Attempt'
      {...props}
      filters={<QuizAttemptFilter />}
      sort={{ field: 'moduleId', order: 'ASC' }}
      actions={<QuizAttemptsListActions />}
    >
      <Datagrid>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <ReferenceField label='Module Title' source='moduleId' reference='AdminModule' sortBy='title' sortable={false}>
          <TextField source='title' />
        </ReferenceField>
        <ReferenceField label='User Email' source='user.id' reference='AdminUser' sortBy='email' sortable={false}>
          <TextField source='email' />
        </ReferenceField>
        <ReferenceField label='State' source='userMeta.state' reference='AdminState' sortable={false}>
          <TextField source='name' />
        </ReferenceField>
        <TextField label='Status' source='status' />
        <LearningCreditField label='Learning Credits' />
        <CentralDateField label='Created At' source='createdAt' />
      </Datagrid>
    </List>
  );
};

export const SecuredQuizAttemptList = lockDown(QuizAttemptList, [isUCPAdmin]);
